.gender-birthday-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .gender-birthday-container .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .gender-birthday-container .form-group label {
    margin-bottom: 5px;
  }
  
  .gender-birthday-container .form-group .form-control {
    width: 100%;
  }

  .login-register-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.login-register-wrapper {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-register-tab-list .nav-link {
    border: none;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.login-register-tab-list .nav-link.active {
    background-color: #ff69b4;
    color: #fff;
}

.login-register-tab-list .nav-link:hover {
    background-color: #ffb6c1;
    color: #fff;
}

/* .login-form-container {
    padding: 20px;
} */

.row{
 margin-left: 20px;
}

.login-register-form .form-control {
    margin-bottom: 15px;
    border-radius: 5px;
}

.button-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-box .btn-primary {
    background-color: #ff69b4;
    border-color: #ff69b4;
    transition: background-color 0.3s, border-color 0.3s;
}

.button-box .btn-primary:hover {
    background-color: #ffb6c1;
    border-color: #ffb6c1;
}

.login-toggle-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.custom-checkbox .form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25em;
}

.custom-checkbox .form-check-label {
    margin-left: 0.5rem;
    font-size: 0.9rem;
}