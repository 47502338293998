.store-profile {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 1800px;
    height: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.tab-header {
    display: flex;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 20px;
}

.tab-header button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 17px;
    color: #333;
    transition: all 0.3s ease;
}

.tab-header button.active {
    border-bottom: 2px solid #F56285;
    color: #F56285;
    font-weight: bold;
}

.tab-content {
    padding: 20px;
}

.basic-info h2 {
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
}

.info-row {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    font-size: 17px;
    
}

.info-row ul {
        font-size: 15px;
    color: #666;
    margin-top: 5px;
    margin-left: 10px;
}
.info-row span:first-child {
    width: 150px;
    font-weight: bold;
    color: #666;
}

.logo-container {
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.default-logo {
    width: 100%;
    height: 100%;
    background-color: #008080;
    color: white;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-requirements ul {
    padding-left: 20px;
    margin-bottom: 20px;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.action-buttons button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.view-shop {
    background-color: #f0f0f0;
    color: #333;
}

.edit {
    background-color: #F56285;
    color: white;
}

.view-shop:hover, .edit:hover {
    opacity: 0.8;
}

/* Responsive design */
@media (max-width: 768px) {
    .store-profile {
        padding: 10px;
    }

    .info-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .info-row span:first-child {
        margin-bottom: 5px;
    }

    .logo-requirements {
        margin-left: 0;
    }

    
}